export interface Attribute {
  name: string
  value: string
  required?: boolean
}

export class Variant {

  id: number
  attributes?: Attribute[]
  unlimitedStock: 0 | 1
  allowNegativeStock: 0 | 1
  stock: {
    office: string
    officeId: number
    quantity: 5
    quantityAvailable: number
    quantityReserved: number
  }[]
  discount: {
    autoApply: 0 | 1
    discountId: number
    id: number
    minimumQuantity: number
    name: string
    percent: number
    state: 0 | 1
  }
  price: number
  priceUnit?: number // price with which the variant was added to the cart

  constructor({ attributes, ...variant }: {
    attributes: Attribute[]
    [key: string]: any
  }) {
    for (const key in variant) {
      this[key] = variant[key]
    }
    this.attributes = []
    for (const key in attributes) {
      this.attributes.push(attributes[key])
    }
  }

  filterByAttributes(attributes: Attribute[]): boolean {
    const _attributes = attributes.filter(({ name, value }) => {
      const [attribute] = this.attributes.filter(attr => attr.name === name)
      if (attribute /* && attribute.required */ && attribute.value === value) {
        return true
      }
      return false
    })
    if (_attributes.length === attributes.length) {
      return true
    }
    return false
  }

  getStock(): number {
    try {
      if (this.unlimitedStock || this.allowNegativeStock) {
        return Infinity //true
      }
      return Math.trunc(this.stock.reduce((accumulator, office) => {
        if (office.quantityAvailable > accumulator) {
          return office.quantityAvailable
        }
        return accumulator
      }, 0))
    }
    catch (error){
      return 0
    }
  }

  getJSON() { }

  getHTML() { }
}
