import { Cart } from './Cart'
import { Collection } from './Collection'
import { Product } from './Product'
import { Plugins } from './Plugins'
import { createModal } from '../util'
import { Tracking } from './Tracking'

declare global {
  interface Window {
    Bsale: {
      cart: Cart
      products: Product[]
      collections: Collection[]
      plugins: Plugins
      config
      dev: boolean
      version: string
    }
  }
}

const INIT = process.env.NODE_ENV === 'test'
  ? global.__TEST_GLOBAL__.INIT
  : window.INIT
const cart = new Cart(INIT.cart)
const products = INIT.products.map(product => new Product(product))
const collections = INIT.collections.map(collection => new Collection(collection))
const config = INIT.config
const dev = location.hostname.indexOf('bsalemarket.com') !== -1
const version = process.env.VERSION
const plugins = new Plugins()
plugins.mountPluginsScripts()

//Se cargan los scripts de tracking
const tracking = new Tracking();
tracking.mountTrackingScripts();

if (!config.addToCartLimit) {
  config.addToCartLimit = Infinity
}

export default {
  cart,
  products,
  collections,
  plugins,
  config,
  dev,
  version,
  util: { createModal }
}
