import bsale from '../Bsale'
import events from './events'
import { log } from './log'
import { track, EventDetail } from './track'

declare global {
  interface Window {
    Metrics
  }
}

const INIT = process.env.NODE_ENV === 'test'
  ? global.__TEST_GLOBAL__.INIT
  : window.INIT
const metrics = {
  customEvent(payload) {
    track('customEvent', payload)
  }
}
const checkoutInfo = {
  ...INIT.checkout,
  currency: bsale.config.currency.isoCode
}
function defineHandler(event: string, handler: (...args: any[]) => EventDetail) {
  Object.defineProperty(metrics, event, {
    get() {
      return (...payload) => {
        let eventDetail
        switch (event) {
          case 'checkout':
          case 'purchase':
            eventDetail = handler(checkoutInfo)
            break
          default:
            eventDetail = handler(...payload)
        }
        track(event, eventDetail)
      }
    },
    set(customHandler) {
      defineHandler(event, customHandler)
    },
    configurable: true
  })
}
for (const event in events) {
  defineHandler(event, events[event])
}

export { metrics }
