export async function get(endpoint: string) {
  const fetch = process.env.NODE_ENV === 'test'
    ? require('node-fetch')
    : window.fetch
  let res
  if (process.env.NODE_ENV === 'test') {
    // simulate session in the test market
    if (!global.__TEST_GLOBAL__.cookies) {
      const res = await fetch(global.__TEST_GLOBAL__.market)
      global.__TEST_GLOBAL__.cookies = res.headers.get('set-cookie')
    }
    res = await fetch(global.__TEST_GLOBAL__.market + endpoint, {
      headers: {
        cookie: global.__TEST_GLOBAL__.cookies
      }
    })
    const newCookies = res.headers.get('set-cookie')
    if (newCookies) {
      global.__TEST_GLOBAL__.cookies = newCookies
    }
  }
  else {
    res = await fetch(endpoint, { credentials: 'same-origin' })
  }
  const { success, data, message } = await res.json()
  if (success) {
    return data
  }
  
  throw new Error(message)
}
