export function formSent(formName) {
  return {
    gtm: {
      event: 'formSent',
      data: {
        formName: formName
      }
    },
    gtmGA4: {
      event: 'form_sent',
      data: {
        event_category: 'Formulario enviado',
        event_label: formName
      }
    },
    analytics: {
      event: 'form_sent',
      data: {
        event_category: 'Formulario enviado',
        event_label: formName
      }
    },
    GA4: {
      event: 'form_sent',
      data: {
        event_category: 'Formulario enviado',
        event_label: formName
      }
    },
    pixel: {
      event: 'Contact'
    }
  }
}
