import LazyLoad from "vanilla-lazyload"
import bsale from '../Bsale'
import { Collection } from "../Bsale/Collection";
import { formatPrice } from '../util/formatPrice'

export let customFunction = null;

export function setCustomFunction(customFunc) {
  customFunction = customFunc;
}
/*
carga video
*/
/*******
cargador de videos
********/

/*function loadVideo() {
  $("[data-bs='video']").each(function () {
    console.log("loadVideo");
    var dataVideo = $(this).data("info");
    try {

      if (dataVideo.indexOf("youtube") != -1 || dataVideo.indexOf("youtu.be") != -1) {
        var code = dataVideo.substring(dataVideo.length, (dataVideo.length - 11));
        $(this).html("<img title='youtube'  src='https://img.youtube.com/vi/" + code + "/maxresdefault.jpg'><i class='icon fab fa-youtube'></i>");
        if (!$(this).closest("#bs-product-thumbnail").length) {
          $(this).on("click", function () {
            $(this).html("<iframe src='https://www.youtube.com/embed/" + code + "?autoplay=1&showinfo=0&rel=0' allowfullscreen allow='autoplay'>");
          });
        }
      }

      else if (dataVideo.indexOf("vimeo") != -1) {
        var apiVimeo = new XMLHttpRequest();
        apiVimeo.responseType = 'json';
        apiVimeo.open('GET', '//vimeo.com/api/oembed.json?url=' + dataVideo, true);


        apiVimeo.onload = function () {

          if (apiVimeo.response != null) {
            $(this).html("<img title='" + apiVimeo.response.title + "' " + "src='" + apiVimeo.response.thumbnail_url_with_play_button.replace('295x166', '1200x676') + "'>");
            if (!$(this).closest("#bs-product-thumbnail").length) {
              $(this).on("click", function () {
                $(this).html(apiVimeo.response.html);
              });
            }
          } else {
            $(this).html("<img title='cambia la configuración de privacidad del video' src='https://via.placeholder.com/600x400?text=Video%20Privado'>");
          }
        }.bind(this);//onload
        apiVimeo.send(null);
      }//vimeo end
      else {
        console.warn("loadVideo() error: formato de video no soportado, porfavor usar sólo youtube o vimeo");
      }
    }
    catch (ex) {

      console.warn("loadVideo() error: " + ex.message);
    }
  });
}*/

function loadVideo() {
  const menuItems = document.getElementById('bs-product-description')
  let videos = document.querySelectorAll("[data-bs='video']");
  let dataVideo;
  let code;
  try{
    for(let n = 0; n < videos.length; n++){
      let video = videos[n] //si no se crea dentro del for se marean los llamados asincronos
      dataVideo = video.dataset.info
      if (dataVideo.indexOf("youtube") != -1 || dataVideo.indexOf("youtu.be") != -1) {//youtube -----------------------------
        code = dataVideo.substr(dataVideo.length-11,11);
        video.innerHTML = `<img title='youtube' src='https://img.youtube.com/vi/${code}/maxresdefault.jpg'><i class='icon fab fa-youtube'></i>`;
        //cargar script como hermano...
        if(!video.closest(".bs-product-thumbnail")){
          video.addEventListener("click", function(){
            video.innerHTML = `<iframe src='https://www.youtube.com/embed/${code}?autoplay=1&showinfo=0&rel=0&rel=0&rel=0' allowfullscreen allow='autoplay'>`;
          });
        }//closest bs-product-thumbnail
        

        //youtube Stop Video -----------------------------------------------
        menuItems.addEventListener('click', e => {
          if(e.target.classList.contains('btn-primary')){
            let iframes = document.querySelectorAll('iframe');
            
            for(let i=0; i < iframes.length-1; i++) {
              let urlVideo = iframes[i].src + '&enablejsapi=1'
              let url = urlVideo.replace(/autoplay=1/gi, 'autoplay=0')
              iframes[i].setAttribute("src",url)
            }
      
            Array.prototype.forEach.call(iframes, iframe => {
              iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
              func: 'stopVideo' }), '*');
            });

          } 
        })
        //youtube -----------------------------------------------------------------------------
      }else if(dataVideo.indexOf("vimeo") != -1){ // vimeo ------------------------------------
        let apiVideo = new XMLHttpRequest();
        apiVideo.responseType = 'json';
        apiVideo.open('GET', `//vimeo.com/api/oembed.json?url=${dataVideo}`, true);
        apiVideo.onload = function () {
          if (apiVideo.response != null) {
            video.innerHTML = `<img title='${apiVideo.response.title}' src='${apiVideo.response.thumbnail_url_with_play_button.replace('295x166', '1200x676')}'>`
            if(!video.closest(".bs-product-thumbnail")){
              video.addEventListener("click", function(){
                video.innerHTML = apiVideo.response.html
              });
            }//closest bs-product-thumbnail
          }// api response
        }//onload
        apiVideo.send(null);

        //vimeo Stop Video----------------------------------------------------------------------------
        
        menuItems.addEventListener('click', e => {
          if(e.target.classList.contains('btn-primary')){
            let iframes = document.querySelectorAll('iframe');
   
            for(let i=0; i < iframes.length-1; i++) {
              let urlVideo = iframes[i].src
              iframes[i].src = urlVideo 
            }
          } 
        })
      }else {
        console.warn("loadVideo() error: formato de video no soportado, porfavor usar sólo youtube o vimeo");
      }
    }// if != undefined
  }catch(ex){
    console.log(ex.message)
  }
}

//Por terminar
function loadMap() {
  /*
  $(".bs-map").each(function(){
      var direction = $(this).data("map");
      $(this).html("<iframe src=\"//www.google.com/maps/embed/v1/search?q="+direction+"&key=AIzaSyAWf0Do0-InjydL_xBrQoQgiAnb2K5Nh0c\" allowfullscreen>");
  });
  */
}

/*********************************************
*  modales y alertas
**********************************************/
function clearHTML(text) {
  return text.toString().replace(/<!--(?!>)[\S\s]*?-->/g, "").replace(/(\r\n\t|\n|\r|\t)/gm, "");
}


function offerAlert() {
  const selector = $("[data-bs='offer.alert']");
  var offAlert = localStorage.getItem('offAlert');
  var info = selector.data("info")

  if (info != undefined) {
    try {
      inicioAlert()
    } catch (error) {
      console.log(error)
    }

  }
  else {
    selector.css("margin", "0");

    if (selector && offAlert == null) {
      selector
        .removeClass('d-none')
        .addClass('alert alert-primary alert-dismissible fade show')
        .append('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>');

      selector.find('button').on('click', function () {
        localStorage.setItem('offAlert', 1);
      })

    }
  }

}


function inicioAlert() {
  const selector = $("[data-bs='offer.alert']");
  const content = selector.length > 0 ? selector.html().replace(/(\r\n|\n|\r|\<!--.*?\-->)/gm, "").trim():undefined


  const delay = selector.data("info") ? selector.data("info").delay * 1000:undefined;
  const show =selector.data("info") ? selector.data("info").show.toLowerCase():undefined;

  function dibujar() {
    setTimeout(() => {
      selector
      .removeClass('d-none')
      .addClass('alert alert-primary alert-dismissible fade show m-0')
      .append('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>');
    });
  }

  if (localStorage["content-alert"] != content && content != "") {
    dibujar();
  }
  else {
    switch (show) {
      case "day":

        let now = new Date
        let past = localStorage.getItem("openAlert") && new Date(parseInt(localStorage.getItem("openAlert")))
        now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        if (past) {
          past = new Date(past.getFullYear(), past.getMonth(), past.getDate());
        }
        if (now > past) {
          dibujar();

        }

        localStorage.setItem("openAlert", Date.now())
        break;
      case "once":
        var isshowAlert = localStorage.getItem('isshowAlert')
        if (localStorage["content-selector"] != content) {
          localStorage.removeItem("isshowAlert");
        }
        if (isshowAlert == null && content) {
          localStorage.setItem('content-selector', content);
          dibujar();
        }
        break;
      default:
        if (content) {
          dibujar();
    
        }
        break;
    }
    
  }

  localStorage.setItem('content-alert', content);
  localStorage.setItem('isshowAlert', '1');

  if (delay >= 1000) {
    setTimeout(function () {
      (selector).addClass('d-none');
    }, delay);
  }

}

function createModal(type, message) {
  try {
    var modal = '<div id="' + type + '" class="modal fade show" tabindex="-1" role="dialog">'
      + '<div class="modal-dialog" role="document">'
      + '<div class="modal-content">'
      + '</div></div></div>';

    $('body').append(modal);
    var modalBody = $('#' + type);
    var modalContent = modalBody.find(".modal-content");
    let newUrl

    if (type == 'add-success') {
      message = 'Producto Agregado';
      newUrl = '/sheet/modal-agregar/';
    } else if (type == 'add-error') {
      newUrl = '/sheet/modal-agregar-error/';
    } else if (type == 'order') {
      newUrl = '/sheet/modal-encargar';
    }

    function orderDetail() {
      var id = $("[data-bs='product.variant'] option:selected, [data-bs='product.variant'] input:checked ").data('info')
      var variant = bsale.products[0].variants.filter(function (v) { return v.id === id })[0]

      var nombreProducto = $("#nombreproducto");
      var skuProducto = $("#skuproducto");
      var enlaceProducto = $("#enlaceproducto");



      nombreProducto ? nombreProducto.val(bsale.products[0].title + variant.title).closest('.form-group').hide() : console.log('falta campo nombre_producto');

      skuProducto ? skuProducto.val(variant.sku).closest('.form-group').hide() : console.log('falta campo variant_producto');
      enlaceProducto ? enlaceProducto.val(window.location.pathname).closest('.form-group').hide() : console.log('falta campo enlace_producto');

    }

    $.ajax({
      url: newUrl,
      type: 'get',
      success: function (data) {
        if (data != '') {
          //aca
          modalContent.html(data.toString().replace(/(\r\n|\n|\r|\<!--.*?\-->)/gm, ""));

          var body = modalContent.find(".modal-body");

          if (body.html().trim().length <= 0) {
            body.html(message);
          }
          if (type == 'order') {
            orderDetail();
          }

          modalBody.modal('show');

        } else {
          console.error('falta componente en plantilla ' + newUrl);
        }
      },
      error: function (xhr, thrownError) {
        console.log(xhr.status);
        console.log(thrownError);
        console.error('no hay platilla ' + newUrl)
      }
    });

    modalBody.on('hidden.bs.modal', function (e) {
      $(this).remove();
    })
  } catch (error) {
    console.log(error.message);
  }
}


let contentModal
function newCreateModal(type, info, modalFooter) {
  
  let modalAdd = document.querySelector("[data-bs='modal.add']");
  let modalError = document.querySelector("[data-bs='modal.error']");
  let modalOrder = document.querySelector("[data-bs='modal.order']");
  

  function scrollWidth() {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return `${scrollbarWidth}px`;
  }
  const scroll = scrollWidth();
  

  function hideModal(modal) {
    let btnHide = modal.querySelector('[data-bs="modal.hidden"]');
    let btnClose = modal.querySelector('[data-bs="modal.close"]');
   
    btnHide?.addEventListener("click", function () {
      modal.style.display = "none";
      document.body.style.paddingRight = "0px";
      document.body.style.overflow = "auto";
    }, false);
    
    btnClose?.addEventListener("click", function () {
      modal.style.display = "none";
      document.body.style.paddingRight = "0px";
      document.body.style.overflow = "auto";
    }, false);
    
    modal.addEventListener("click", function (e) {
      if (e.target == modal) {
        modal.style.display = "none";
        document.body.style.paddingRight = "0px";
        document.body.style.overflow = "auto";
      }
    })
  }

  function showModal(modal) {
    modal.style.display = "block"
    modal.style.background = "rgba(0,0,0,0.5)";
    modal.style.paddingRight = scroll;
    document.body.style.paddingRight = scroll;
    document.body.style.overflow = "hidden";
  }

  function deleteModal(modal) {
    let btnDelete = modal.querySelector('[data-bs="modal.delete"]')
    if (btnDelete !== null) {
      btnDelete.addEventListener("click", function () {
        modal.parentNode.removeChild(modal);
        document.body.style.paddingRight = "0";
        document.body.style.overflow = "auto";
      }, false);
    }
    modal.addEventListener("click", function (e) {
      if (e.target == modal) {
        modal.parentNode.removeChild(modal);
        document.body.style.paddingRight = "0";
        document.body.style.overflow = "auto";
      }
    })
  }

  // Pop-up que se muestra al agregar un producto desde una coleccion
  function createModalHtml(type, info, modalFooter) {
   
    const modal = document.createElement("div");

    modalFooter = modalFooter || `<button type="button" class="btn btn-primary" data-bs="modal.delete">Aceptar</button>`;
    if (type === "add") {
      
      modalFooter = `
      <button type="button" class="btn btn-secondary" data-bs="modal.delete">Seguir Comprando</button>
      <a href="/cart/display/" class="btn btn-primary">Ver mi Carro</a>
      `;
      
      info = `<div style="display:flex;">
                ${bsale.products[0] ? 
                  `<div style="width:160px; padding-right:1rem">
                   <img style="max-width:100%; height:auto;" src="${info.variant.image}">
                </div>`: " "}
                <div>
                  <h5>${info.variant.productName}</h5>
                  <h3>${formatPrice(info.variant.subTotal)}</h3>
                  Cantidad: ${info.quantity} 
                </div>
              </div>`;
    }

    modal.setAttribute("data-bs", "modal.new");
    modal.setAttribute("id", "modal-new");
    modal.classList.add("modal", "show");
    modal.innerHTML = `<div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-body">
                                  ${info}
                                </div>
                                <div class="modal-footer">
                                  ${modalFooter}
                                </div>
                              </div>
                            </div>`;
                            
    document.body.appendChild(modal);

    //agrega evento delete
    deleteModal(modal);
    showModal(modal);
  }

  //Crea el modal cuando se agrega al carro desde colección
  async function createModal(type, info, modalFooter) {
    // Si esta en el resumen del carro se retorna para no crear el modal
    if ((window.location.pathname === '/cart/display/carro' || window.location.pathname === '/cart/display/') && type != "select" && type != "error") {
      return;
    }
    //const newModal = document.createElement("div");
    const newModal = document.querySelector('[data-bs="modal.new"]');
    if (type === 'add' && info.variant && !info.isRelatedProduct) {

      const productName     = document.querySelector('[data-bs="modal.product.name"]');
      const productPrice    = document.querySelector('[data-bs="modal.product.price"]');
      const productQuantity = document.querySelector('[data-bs="modal.product.quantity"]');
      const productImage    = document.querySelector('[data-bs="modal.product.image"]');

      if (!newModal) {
        createModalHtml(type, info, modalFooter);
        return;
      }
      
      const data = await getRelatedProducts(info.variant.tagProduct);
      console.log('Data...', data);

      productName?.textContent = info.variant.productName;
      productPrice?.textContent = formatPrice(info.variant.subTotal);
      productQuantity?.textContent = info.quantity;
      productImage?.innerHTML = `<img class="img-fluid" src="${info.variant.image}">`;
      showModal(newModal);
      hideModal(newModal);
    }
    else if (type === 'error' || type === 'select') {
      createModalHtml(type, info, modalFooter);
    }
    
  }

  // si existe el modal
  if (type == "add") {
    // si se esta en la pantalla de resumen del carro
    if (window.location.pathname === '/cart/display/carro' || window.location.pathname === '/cart/display/') {
      return;
    }
    if (modalAdd === null) {
      createModal("add",info, modalFooter);
    } else {

      if(!contentModal){
        contentModal = modalAdd.innerHTML
      }

      let newtext = contentModal
        .replace("{product.image}", info.variant.image)
        .replace("{product.name}", info.variant.name)
        .replace("{product.price}", formatPrice(info.variant.subTotal))
        .replace("{product.quantity}", info.quantity)

        modalAdd.innerHTML = newtext;

      showModal(modalAdd)
      hideModal(modalAdd)

    }

  } else if (type == "error") { //se dibuja el modal si hay error
    if (modalError === null) {
      createModal("error", info, modalFooter);
    } else {
      showModal(modalError)
      hideModal(modalError)
    }
  } else {
    if (type == "order" && modalOrder !== null) {
     
      let nombreTxt = document.getElementById("nombreproducto");
      let skuTxt = document.getElementById("skuproducto");
      let enlaceTxt = document.getElementById("enlaceproducto");
      if (nombreTxt !== null) {
        nombreTxt.parentNode.style.display = "none"
        nombreTxt.value = `${bsale.products[0].title} ${info.title}`;
      } else {
        console.log("formulario encargar, falta el campo: nombre_producto")
      }
      if (skuTxt !== null) {
        skuTxt.parentNode.style.display = "none"
        skuTxt.value = info.sku;
      } else {
        console.log("formulario encargar, falta el campo: sku_producto")
      }
      if (enlaceTxt !== null) {
        enlaceTxt.parentNode.style.display = "none"
        enlaceTxt.value = Bsale.products[0].link;
      } else {
        console.log("formulario encargar, falta el campo: enlace_producto")
      }

      showModal(modalOrder)
      hideModal(modalOrder)

    }else if(type === 'order'){
      //soporte template antiguos 
      console.log('%cBsale UPDATE %cActualiza componente y formulario encargar documentación en https://github.com/gmontero/bsale-market-design-doc/wiki',
              'padding:3px;color:#fff;background-color:black',
              'padding:3px'
      );
      try {
        var modal = '<div id="' + type + '" class="modal fade show" tabindex="-1" role="dialog">'
          + '<div class="modal-dialog" role="document">'
          + '<div class="modal-content">'
          + '</div></div></div>';
    
        $('body').append(modal);
        var modalBody = $('#' + type);
        var modalContent = modalBody.find(".modal-content");
        let newUrl = '/sheet/modal-encargar';
        
    
        function orderDetail() {
          var id = $("[data-bs='product.variant'] option:selected, [data-bs='product.variant'] input:checked ").data('info')
          var variant = bsale.products[0].variants.filter(function (v) { return v.id === id })[0]
    
          var nombreProducto = $("#nombreproducto");
          var skuProducto = $("#skuproducto");
          var enlaceProducto = $("#enlaceproducto");
    
    
    
          nombreProducto ? nombreProducto.val(bsale.products[0].title + variant.title).closest('.form-group').hide() : console.log('falta campo nombre_producto');
    
          skuProducto ? skuProducto.val(variant.sku).closest('.form-group').hide() : console.log('falta campo variant_producto');
          enlaceProducto ? enlaceProducto.val(window.location.pathname).closest('.form-group').hide() : console.log('falta campo enlace_producto');
    
        }
    
        $.ajax({
          url: newUrl,
          type: 'get',
          success: function (data) {
            if (data != '') {
              //aca
              modalContent.html(data.toString().replace(/(\r\n|\n|\r|\<!--.*?\-->)/gm, ""));
    
              var body = modalContent.find(".modal-body");
    
              if (body.html().trim().length <= 0) {
                body.html(message);
              }
              if (type == 'order') {
                orderDetail();
              }
    
              modalBody.modal('show');
              
            } else {
              console.error('falta componente en plantilla ' + newUrl);
            }
          },
          error: function (xhr, thrownError) {
            console.log(xhr.status);
            console.log(thrownError);
            console.error('no hay platilla ' + newUrl)
          }
        });
    
        modalBody.on('hidden.bs.modal', function (e) {
          $(this).remove();
        })
      } catch (error) {
        console.log(error.message);
      }
    } else {
      createModal(type, info, modalFooter)
    }
  }
}


// Nuevo
function initSlider(slider = null) {
  try {
    let sliders = []
    slider ? sliders.push(slider) : sliders = document.querySelectorAll('[data-bs="slider"]');

    sliders.forEach(el => {
      const { info } = el.dataset
      if (info) {
        const options = JSON.parse(info)
        if (el.className.indexOf('owl-carousel') !== -1) {
          $(el).owlCarousel(options);
        }
        else {
          $(el).slick(options);
        }
      }
      else {
        throw new Error('Slider no tiene opciones')
      }
    });
  }
  catch (error) {
    console.error(error)
  }
}

//cargar imagenes despues de cargado todo el contenido
new LazyLoad({
  elements_selector: '.lazy'
})


//formateo de monedas
/*
function localeMoney(num) {
  return parseFloat(num).toLocaleString(bsale.config.lang, { style: 'currency', currency: bsale.config.currency.isoCode })
}
*/

function contactPhone() {
  try{
    const isMobile = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    const tel = document.querySelectorAll('[data-bs="contact.phone"]')

    for(let i = 0; i < tel.length ; i++){
      if(isMobile){
        tel[i].href = `tel:${tel[i].dataset.info.replace(/\s/g, '')}`
      }else{
        tel[i].addEventListener('click', e => e.preventDefault())
      }
    }
  }catch(ex){
    console.log(ex)
  }
  /*
  document.querySelectorAll('[data-bs="contact.phone"]').forEach(el => {
    if (isMobile) {
      el.href = 'tel:' + el.dataset.info.replace(/\s/g, '')
    }
    else {
      el.addEventListener('click', e => e.preventDefault())
    }
  })
  */
}

function contactChat() {
  try {
    const fbBtn = document.querySelectorAll('[data-bs="chat.facebook"]');

    for(let i = 0; i< fbBtn.length; i++){
        let fb = fbBtn[i];
        fb.href = `https://m.me/${fb.href.substr(fb.href.indexOf(".com/") + 5)}`
    }
    
    /*
    let link = fbUser.href.substring(fbUser.href.indexOf(".com/") + 5, fbUser.href.length);

    // evalua dispositivo
    
    if (navigator.userAgent.toLowerCase().indexOf('mobile') > -1) {
      fbUser.href = "https://m.me/" + link;
    } else {
      fbUser.href = 'https://www.messenger.com/t/' + link;
    }
    
    console.log(`https://m.me/${fbLink}`)
    fbUser.href = `https://m.me/${fbLink}`; //new
    console.log(fbUser)
    */

    if (navigator.userAgent.toLowerCase().indexOf('mobile') < 0) {
      const firefox = document.querySelectorAll('[data-bs="chat.whatsapp"]')
      for(let i = 0; i< firefox.length; i++){
        let fi = firefox[i];
        fi.href = fi.href.replace('api', 'web')
      } 
    }
  } catch (ex) {
    console.log(ex.message);
  }
};

// function formSender() {
//   document.querySelectorAll('[id^="formImaginex"]').forEach(el => {
//     const { onsubmit } = el
//     el.onsubmit = e => {
//       metrics.formSent(e.target.action.substr(e.target.action.lastIndexOf('/') + 1))
//       onsubmit(e)
//       // e.preventDefault()
//     }
//   })
// }


async function getRelatedProducts(pwfId = 393) {
  const target = document.getElementById('related_carro_modal');
  // get all atributes target that start with 'data-param-'
  let dataParams = {};
  let attrs = target.attributes;
  for (let i = 0; i < attrs.length; i++) {
    if (attrs[i].name.startsWith('data-param-')) {
      dataParams[attrs[i].name.replace('data-param-', '')] = attrs[i].value;
    }
  }

  let queryParams = '';
  if(Object.keys(dataParams).length > 0) {
    queryParams = `?${ Object.keys(dataParams).map(key => `${key}=${dataParams[key]}`).join('&') }`;
  }
  let response = null;
  let data = null;
  try {    
    response = await fetch(`/cart/related/${ pwfId }${ queryParams }`);
    data = await response.json();
  }
  catch {
    console.error('Cannot get related products');
    return {};
  }

  if (data.collection && data.collection.length == 0) {
    console.warn('Related products is empty');
    return {};
  }

  bsale.collections.push(new Collection({
    name: dataParams['title'],
    items: data.collection
  }));

  //target.innerHTML = data.html;
  customFunction(target, data.html, '.carro_related_products');
  return {
    target: target,
    collection: data.collection,
    html: data.html
  };
}

//export { loadVideo, loadMap, initSlider, offerAlert, inicioAlert, clearHTML, localeMoney, createModal, contactPhone, contactChat }
export { loadVideo, loadMap, initSlider, offerAlert, inicioAlert, clearHTML, newCreateModal, createModal, contactPhone, contactChat }
