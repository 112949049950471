export async function post(endpoint: string, body: BodyInit) {
  let res = await fetch(endpoint, {method: 'POST', headers: {'Content-Type': 'application/json'}, body})
  let response;
  if (res!= undefined && res!=null && res.status<300) {
    response = await res.json()
  } else {
    response = {
      code: 500,
      error: response
    }
  }
  return response;
}
