export function checkout(checkoutInfo) {
  return {
    gtm: {
      event: 'checkout',
      data: {
        ecommerce: {
          checkout: {
            actionField: {
              step: 1
            },
            products: checkoutInfo.detail.map(function (variant) {
              return {
                id: variant.sku,
                name: variant.name,
                variant: variant.name,
                quantity: variant.quantity,
                price: variant.price || variant.value
              }
            })
          }
        }
      }
    },
    gtmGA4:{
      event: 'begin_checkout',
      data: {
        ecommerce:{
          items: checkoutInfo.detail.map(function (variant) {
            return {
              item_id: variant.sku,
              item_name: variant.name,
              item_variant: variant.name,
              quantity: variant.quantity,
              price: variant.price || variant.value
            }
          })
        }
      }
    },
    analytics: {
      event: 'begin_checkout',
      data: {
        currency: checkoutInfo.currency,
        value: checkoutInfo.total,
        items: checkoutInfo.detail.map(function (variant) {
          return {
            id: variant.sku,
            name: variant.name,
            variant: variant.name,
            quantity: variant.quantity,
            price: variant.price || variant.value
          }
        })
      }
    },
    GA4:{
      event: 'begin_checkout',
      data: {
        transaction_id: Date.now().toString(),
        currency: checkoutInfo.currency,
        value: checkoutInfo.total,
        items: checkoutInfo.detail.map(function (variant) {
          return {
            item_id: variant.sku,
            item_name: variant.name,
            item_variant: variant.name,
            quantity: variant.quantity,
            price: variant.price || variant.value
          }
        })
      }
    },
    pixel: {
      event: 'InitiateCheckout',
      event_id: Date.now().toString(),
      data: {
        currency: checkoutInfo.currency,
        value: checkoutInfo.total,
        content_ids: checkoutInfo.detail.map(function (variant) {
          return variant.sku
        }),
        content_type: 'product_group',
        contents: checkoutInfo.detail.map(function (variant) {
          return {
            id: variant.sku,
            quantity: variant.quantity,
            item_price: variant.price || variant.value
          }
        })
      }
    }
  }
}
