import { Variant } from './Variant'

export class CartItem {

  id: number
  quantity: number
  variant: Variant

  constructor({ id, quantity, variant }: {
    id: number
    quantity: number
    variant: any
  }) {
    this.id = id
    this.quantity = quantity
    this.variant = new Variant(variant)
  }

  update({ productCartId, quantity, ...variant }): CartItem {
    this.id = productCartId
    this.quantity = quantity
    for (const key in variant) {
      this.variant[key] = variant[key]
    }
    return this
  }
}
