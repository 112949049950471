import bsale from '../Bsale'
import { newCreateModal, createModal } from '../util'
import { metrics } from '../Metrics'
import { updateHeader, updateCart } from './cart'
import { formatPrice } from '../util/formatPrice'
import Drift from 'drift-zoom';

export function product() {
  let oldPrice
  if(Bsale.products[0] !== undefined){
    metrics.viewProduct(bsale.products[0], bsale.products[0].variants.filter(variant => variant.id === bsale.products[0].variantId)[0])

    setTimeout(initHoverZoom());
    //var del producto
    const skuHTML = document.querySelectorAll("[data-bs='product.sku']")
    let variantSelect = document.querySelectorAll('[data-bs="product.variant"]');
    let variantSelectParent; //para soporte estructura antigua

    if(variantSelect.length > 0 && variantSelect[0].tagName === "DIV"){
      variantSelectParent = document.querySelector('[data-bs="product.variant"]')
      variantSelect = variantSelectParent.querySelectorAll("input[type='radio']");
    }
    
    const variantSelectOption = Array.from(variantSelect).filter(x => x.tagName === "SELECT");
    const variantSelectCheck = Array.from(variantSelect).filter(x => x.tagName === "INPUT");
  
    const attrSelect = document.querySelectorAll('select[data-bs="product.attributes"]');
    
    //Se agrega obtencion de atributos en input.
    const attrInput = document.querySelectorAll('button[data-bs="product.attributes"]');
    let option; 
    const completePrice = document.querySelectorAll('[data-bs="product.completePrice"]');
    const discountTagHTML = document.querySelectorAll("[data-bs='product.discount']");

    const finalPriceHTML = document.querySelector('[data-bs="product.finalPrice"]');
    const finalPriceTxt;
    const finalPriceFormat;
    let variantDuplicated = []; //Variantes con mismos valores en atributos obligatorios.
    
    if(finalPriceHTML != null){
      finalPriceTxt = finalPriceHTML.innerHTML.replace(/\r?\n|\t\r/g, "");
      finalPriceFormat = finalPriceHTML.outerHTML.replace(finalPriceTxt, "{finalPrice}")
    }

    // sino no existe completePrice
    let productPrice = document.querySelectorAll("[data-bs='product.price']");
    let discountConditionHTML = document.querySelectorAll("[data-bs='discount.condition']");
    let discountInfoHTML = document.querySelectorAll("[data-bs='discount.info']");

    const discountConditionHTMLtxt = [];
    const discountInfoHTMLtxt = [];

    const modalOrder;

    for(let i = 0; i < discountInfoHTML.length; i++){
      discountInfoHTMLtxt.push(discountInfoHTML[i].innerHTML);
    }

    for(let i = 0; i < discountConditionHTML.length; i++){
      discountConditionHTMLtxt.push(discountConditionHTML[i].innerHTML);
    }

    //btns
    const btnAdd = document.querySelectorAll("[data-bs='cart.add']");
    const btnOrder = document.querySelectorAll("[data-bs='product.order']");
    

    if (Bsale.products[0].collections == 0){
      for (var i = 0; i < btnAdd.length; i++) {
        btnAdd[i].classList.add("noCollection");
      }
      for (var i = 0; i < btnOrder.length; i++) {
        btnOrder[i].classList.add("noCollection");
      }
    }

    //quantity
    const quantityInput = document.querySelectorAll('[data-bs="product.quantity"]');
    const quantityMinus = document.querySelectorAll('[data-bs="product.quantity.minus"]');
    const quantityPlus = document.querySelectorAll('[data-bs="product.quantity.plus"]');
    let quantity = parseInt(quantityInput[0].value) || 1;
    let stock;

    //variant //se define al momento de cargar la pagina 
    let productVariant = bsale.products[0].variants.filter(x => Bsale.products[0].variantId == x.id)[0];

    //stock
    let stockHTML = document.querySelectorAll("[data-bs='product.stock']");

    //stock sucursal
    const selector = document.querySelectorAll('[data-bs="product.stock.detail"]');

    function fullStock(variant) {
      if(variant){
        return variant.allowNegativeStock || variant.unlimitedStock ? Infinity : variant.getStock();
      }else{
        return 0;
      }
    }

    // evalue si la variante por defecto tiene stock, sino la reemplaza por otra variante
    if (fullStock(productVariant) < 1) {
      //si la variante por defecto no tiene stock, busca variantes con stock 
      let otherVariant = bsale.products[0].variants.filter(x => (x.unlimitedStock || x.allowNegativeStock || x.getStock()) && Bsale.products[0].variantId != x.id)[0];
      //si niniguna tiene stock, devuelve la variante por defecto. 
      productVariant = otherVariant ? otherVariant : productVariant;
    }

    // si es un select selecciona la option = a productVariant; asi se selecciona un producto que se pueda comprar por defecto. 
    for(let i = 0; variantSelectOption.length > i;i++){
      let sel = variantSelectOption[i];
      for(let j = 0; sel.options.length > j ; j++){
        let op = sel.options[j];
        if(op.dataset.info == productVariant.id){
          op.selected = true;
        }else{
          op.selected = false;
        }
      }
    }

    //Selector por radio button
    for(let i = 0; variantSelectCheck.length > i; i++){
      let check = variantSelectCheck[i];
      if(check.dataset.info == productVariant.id){
        check.checked = true;
      }else {
        check.checked = false;
      }
    }

    function showVariantSelect() {
      if(variantSelectParent != null){
        variantSelectParent.style.display = "block";
        variantSelectParent.style.opacity = "1";
        variantSelectParent.classList.remove("d-none");
      }
      for (let i = 0; i < variantSelect.length; i++) {
        let v = variantSelect[i];

        if (v.tagName == "INPUT") {
          //oculta input y label
          let child = v.parentNode.children
          for (let i = 0; i < child.length; i++) {
            if (child[i].tagName !== "INPUT") {
              child[i].style.display = "block";
              child[i].style.opacity = "1";
              child[i].classList.remove("d-none");
            }
          }
        } else {
          v.style.display = "block";
          v.style.opacity = "1";
          v.classList.remove("d-none");
        }//if / else
      }//for
    }

    function showBtn(btn) {
      btn.style.opacity = "1";
      btn.style.display = "inline";
      btn.classList.remove("d-none")
    }
    function hideBtn(btn) {
      btn.style.opacity = "0";
      btn.style.display = "none";
      btn.classList.add("d-none")
    }

    function sliderGoTo(variant) {
      const carousel = document.getElementById("bs-product-slider")
      if (carousel) {
        const images = carousel.querySelectorAll(`.item[data-info*="${variant.sku}"]`)
        let image
        for (const _image of images) {
          if (_image.dataset.info.split(",").indexOf(variant.sku) != -1) {
            image = _image
            break
          }
        }
        if (image && "slickIndex" in image.dataset) {
          $(carousel).slick("slickGoTo", parseInt(image.dataset.slickIndex))
        }
      }
    }// end: slideGoTo

    function updateVariant(e) {
      let variant = productVariant;
      let variantId

      if (this?.tagName === "INPUT") {
        // si es un input 
        variantId = parseInt(this.dataset.info) || bsale.products[0].variantId;
        variant = bsale.products[0].variants.filter(function (v) { return v.id === variantId })[0];
        
        for (let i = 0; i < variantSelectOption.length; i++) {
          option = Array.from(variantSelectOption[i]);
          for (let j = 0; j < option.length; j++) {
            if (option[j].dataset.info == this.dataset.info) {
              option[j].selected = true;
            } else {
              option[j].selected = false;
            }
          }
        }
      } else if (this?.tagName === "SELECT") {
        // si es un select
        option = this.options[this.selectedIndex];
        variantId = parseInt(option.dataset.info) || bsale.products[0].variantId;

        variant = bsale.products[0].variants.filter(function (v) { return v.id === variantId })[0];
        for (let i = 0; i < variantSelectCheck.length; i++) {
          if (variantSelectCheck[i].dataset.info == option.dataset.info) {
            variantSelectCheck[i].checked = true;
          }
        }
        for (let i = 0; i < variantSelectOption.length; i++) {
          variantSelectOption[i].selectedIndex = this.selectedIndex;
        }
      }

      // evalua que tipo de variable de variant.taxes y ocupa diferentes 
      //formas de obtener el precio con typeof para hacerlo compatible con la forma antigua
      //precio antiguo 
      if (typeof variant.taxes === "object") {
        let { firstTaxPercentage , amountTax , overTaxPercentage } = variant.taxes;
        const priceWithTax = variant.price * firstTaxPercentage;
        oldPrice = formatPrice((variant.price + (priceWithTax + amountTax)) * (1 + overTaxPercentage) );
      } else {
        // Forma antigua de calcular el precio sin descuento
        oldPrice = formatPrice(variant.price + variant.price * variant.taxes);
      }

      // si permite stock negativo o permite unlimited su stock es infino, si no retorno stock
      stock = fullStock(variant);

      if (attrSelect.length <= 1 && bsale.products[0].variants.length > 1) {
        showVariantSelect();
      }

      //insert sku
      for (let i = 0; i < skuHTML.length; i++) {
        if (variant.sku != undefined) {
          skuHTML[i].innerHTML = `SKU: ${variant.sku}`;
        }
        else {
          skuHTML[i].innerHTML = `SKU: -`;
        }
      }
      //funciones extra
      defineButton(stock);
      sliderGoTo(variant);
      defineStock(variant);
      definePrice(variant);
      updateQuantity();
      //redefine variantes
      return productVariant = variant;
    }//updateVariant

    /*
    function updateAttr(e) {
      let reset = [];
      let attrName = this.dataset.info || "";
      let attrValue = this.value || "";
      let optionDisabled = this.options[this.options.selectedIndex].className.indexOf('bs-option-disabled') !== -1;
      let attrList = bsale.products[0].attributes.filter(function (attr) { return attr.name == attrName })[0];

      //console.log(attrList)
      if (optionDisabled) {
        reset = bsale.products[0].attributes
          .filter(function (attribute) {
            return attribute.name !== attrName
          })
          .map(function (attribute) {
            return attribute.name
          });
        //compara attr con option 
        for (let i = 0; i < attrSelect.length; i++) {
          let select = attrSelect[i];
          if (select.dataset.info !== attrName) {
            select.value = "reset"; //reinicia selector 
          }
          for (let j = 0; j < select.options.length; j++) {
            let option = select.options[j]
            option.classList.remove('bs-option-disabled');
            if (option.value !== 'reset') {
              option.text = option.value;
            }
          }
        }
      }
      //selectores que no tienen valor 0 o reset inicial
      //let selectWithoutReset = Array.from(attrSelect).filter(at => at.value !== 'reset' && at.dataset.info !== this.dataset.info)

      //console.log(selectWithoutReset)

      //trae variant por attr
      bsale.products[0].getVariantsByAttribute(attrList, attrValue, { stock: false, reset: reset }).then(function (newVariants) {
        
        //Deshabilita botón agregar al carro, sku y stock si la variante no existe.
        if(newVariants.length == 0){
          for(const button of [...btnAdd, ...btnOrder]) {
          button.setAttribute("disabled", true);
          button.style.opacity = ".5";
          button.style.cursor = "not-allowed";
          }

          for(const sku of skuHTML){
            sku.style.visibility = "hidden";
          }

          for(const stock of stockHTML){
            stock.style.visibility = "hidden";
          }

          for(const select of selector){
            select.style.visibility = "hidden";   
          }
    
          console.log('La variante seleccionada no existe');
          return
        }else{
          for (const button of [...btnAdd, ...btnOrder]) {
            button.removeAttribute("disabled");
            button.style.cursor = "pointer";
          }
          for(const sku of skuHTML){
            sku.style.visibility = "visible";
          }
          for(const stock of stockHTML){
            stock.style.visibility = "visible";
          }
          for(const select of selector){
            select.style.visibility = "visible";  
          }
        }

        let newAttrList = bsale.products[0].attributes.map(function (attribute) {  
          return {
            name: attribute.name,
            values: []
          };
        });

        //newAttrList = lista de atributos definidos por las nuevas variables
        //se recorren las nuevas variantes

        //console.log("newVariants:",newVariants)

        for (let i = 0; i < newVariants.length; i++) {

          for (let k = 0; k < newVariants[i].attributes.length; k++) {
            let attribute = newVariants[i].attributes[k];
            if (attribute !== attrName) {
              var attr = newAttrList.filter(function (attr) {
                return attr.name === attribute.name
              })[0]
              attr.values.push(attribute.value)
            }
          }
        }

        // lista de attributos no seleccionados 
        let otherAttr = newAttrList.filter(at => at.name !== attrName)
        //console.log("otherAttr:",otherAttr)
        // por cada atributo no seleccionado 
        for (let k = 0; k < otherAttr.length; k++) {
          let oAttr = otherAttr[k];
          //busca el selector de ese atributo 
          let selector = Array.from(attrSelect).filter(at => at.dataset.info === oAttr.name)[0];
          if(selector != undefined){ 
              // evalua sus opciones 
                for (let j = 0; j < selector.options.length; j++) {
                  let option = selector.options[j];
                  // si el valor del atributo esta y no es reset 
                  if (option.value !== 'reset' && oAttr.values.indexOf(option.value) !== -1) {
                    option.classList.remove('bs-option-disabled');
                    option.text = option.value.replace("(no disponible)", "");
                    //si no es reset y no esta agrega texto no disponible
                  } else if (option.value !== 'reset') {
                    option.classList.add('bs-option-disabled');
                    option.text = `${option.value} (no disponible)`;
                  }
                }//for j
          }//if
        }//for k
        
        if (newVariants.length === 1 && !Array.from(attrSelect).filter(x => x.selectedIndex === 0).length) {
          // define nueva variable y ejecuta update variant
          productVariant = newVariants[0];
          updateVariant();
        }

      })
    }
*/

    function variantStock(variant) {
      let totalStock = 0
      variant.stock.forEach(s => {
        totalStock += s.quantityAvailable
      })
      return totalStock
    }

    //----------------------------------------------------------------------------------
    //FUNCION QUE RETORNA LA/S VARIANTE DISPONIBLE EN BASE A LOS ATRIBUTOS SELECCIONADOS.
    function filterByAttr($attrs){
      //$attrs recibe una estructura [{name: "string", value: "string"},...]

      let variantAvailable = bsale.products[0].variants

      for(let i = 0; i< $attrs.length; i++ ){
        variantAvailable = variantAvailable.filter(v => {
          //filtra variable segun atributos
          const atr = v.attributes.filter(at => at.name === $attrs[i].name && at.value === $attrs[i].value)
          //si encuentra resultado retorna la variable
          if(atr.length){
            return v
          } 
        })
      }
      return variantAvailable
    }

    function attributesNonStock(attrSelected = false) {

      let variantAvailable = bsale.products[0].variants

      let attributesStock     = []
      let attributesNoneStock = []

      variantAvailable.forEach(variant => {
        let stock = variantStock(variant)
        if (variant.allowNegativeStock == 1) stock = 9999
        let attrs = variant.attributes.map(va => va.value)
        if ((stock > 0 && attrs.includes(attrSelected)) || (stock > 0 && !attrSelected)) { //&& attrs.includes(attrSelected) 
          attributesStock = [...attributesStock, ...attrs]
        }
        else {
          attributesNoneStock = [...attributesNoneStock, ...attrs]
        }
      })

      attributesNoneStock = attributesNoneStock.filter(ans => { if (!attributesStock.includes(ans)) return ans })     
      return attributesNoneStock
    }

    function getVariantDuplicated() {
      let attributes = []
      let variantAvailable = bsale.products[0].variants
      variantAvailable.forEach(variant => {
        let atr = []
        variant.attributes.forEach(attr => {
          if (attr.required == 1) { atr.push(attr.value) }
        })
        attributes.push(atr.sort())
      })
      
      variantDuplicated = attributes.reduce((obj, attr) => {
        if (!obj[attr]) obj[attr] = 1
        else obj[attr]++
        return obj
      }, {}); 

      // let foo = [];
      // for(let attr in variantDuplicated){
      //   if (variantDuplicated[attr] > 1){
      //     let isDup = true
      //     attr.split(',').forEach(a => {
      //       for(let attraux in variantDuplicated) {
      //         if(attraux == attr) continue;
      //         if (attraux.split(',').includes(a)) isDup = false  
      //       } 
      //       if (isDup) foo.push(a)
      //     })
      //   }
      // }
    }

    function attributesNotAvailableByDuplicity(attrSelected = false) {
      let attributes = []

      if (!attrSelected) {
        for(let attr in variantDuplicated){
          if (variantDuplicated[attr] > 1){
            let isDup = true
            attr.split(',').forEach(a => {
              for(let attraux in variantDuplicated) {
                if(attraux == attr) continue;
                if (attraux.split(',').includes(a)) isDup = false  
              } 
              if (isDup) attributes.push(a)
            })
          }
        }
      }
      else {
        for(let attr in variantDuplicated){
          if (variantDuplicated[attr] > 1 && attr.split(',').includes(attrSelected)){
            attributes = [...attr.split(','), ...attributes]
          }
        }
      }

      return attributes
    }

    //SIRVE PARA TRAER LOS ATRIBUTOS DISPONIBLES EN BASE AL SELECCIONADO.
    function attributesAvailable(attrSelected) {
      let filters = [{
        name: attrSelected.dataset.info,
        value: attrSelected.value
      }]
      let variantAvailable = filterByAttr(filters)
      
      let attrAvailable = []
      let thisAttr = attrSelected.dataset.info || "";
      for(let i = 0;i< variantAvailable.length;i++ ){
        for(let j = 0;j < variantAvailable[i].attributes.length;j++){
          if(!attrAvailable.includes(variantAvailable[i].attributes[j].value)){
            if (variantAvailable[i].attributes[j].value == null) {
              attrAvailable.push('')
            }
            else {
              attrAvailable.push(variantAvailable[i].attributes[j].value.toString())
            }
          }
        }
      }
      let attrNotAvailable = attributesNotAvailableByDuplicity(attrSelected.value)
      if (attrNotAvailable.length > 0){
        attrAvailable = attrAvailable.filter(aa => !attrNotAvailable.includes(aa))
      }

      return attrAvailable
    }

    //---------------------------------------------
    //FUNCION QUE RETORNA LOS ATRIBUTOS DISPONIBLES. OLD
    function attributesAvailableSelect(attrSelected) {
      
      //otherAttrSelect llama al resto de selectores, diferentes del seleccionado y con un valor diferente de reset
      let otherAttrSelect = Array.from(attrSelect).filter(sel => sel !== attrSelected && sel.value !== 'reset');
      //filter array de atributos seleccionados en los "otros selectores"
      let filters = []
      //push de filter [{name: "string", value: "string"},...]
      for(let i = 0; i<otherAttrSelect.length; i++){
        let attr = {
          name: otherAttrSelect[i].dataset.info,
          value: otherAttrSelect[i].value
        }
        filters.push(attr)
      }

      //LLAMA AL RESTO DE INPUT SELECCIONADOS.
      let otherAttrInput = Array.from(attrInput).filter(inp => inp.getAttribute('clicked') === '')
      //push de filter [{name: "string", value: "string"},...]
      for(let i = 0; i<otherAttrInput.length; i++){
        let attr = {
          name: otherAttrInput[i].dataset.info,
          value: otherAttrInput[i].value
        }
        filters.push(attr)
      }
      
      // devuelve las variantes segun los atributos seleccionados (filter)
      let variantAvailable = filterByAttr(filters)

      let attrAvailable = []
      let thisAttr = attrSelected.dataset.info || "";
      for(let i = 0;i< variantAvailable.length;i++ ){
        for(let j = 0;j < variantAvailable[i].attributes.length;j++){
          if(!attrAvailable.includes(variantAvailable[i].attributes[j].value)){
            attrAvailable.push(variantAvailable[i].attributes[j].value.toString())
          }
        }
      }

      return attrAvailable

    }

    //---------------------------------------------------------
    //Se ejecuta cuando se selecciona un un select de atributos.
    function clearAttr(e){
      let attrAvailable = attributesAvailableSelect(e.target)
      // let noneStock = attributesNonStock(e.target.value)
      // if (attrAvailable.length == 0) return
      //recorre optiones segun atributos habilitados
      for(option of e.target){
        if(attrAvailable.includes(option.value)){
          option.classList.remove('bs-option-disabled');
          option.innerText = option.value
        }else if(option.value !== 'reset'){
          option.classList.add('bs-option-disabled');
          option.innerText = `${option.value} (no disponible)`
        }
      }
    }

    //---------------------------------------------------------
    //SE EJECUTA CUANDO SE SELECCIONA UN ATRIBUTO DE TIPO BUTTON.
    function selectAttrInput(e) {
      let buttonSelected = e.currentTarget

      //SE OBTIENE CLASE CLASS-SELECTED (ESTILO SELECCIONADO).
      let selectClass = buttonSelected.getAttribute('class-selected')
      //SE OBTIENE TIPO DE ATRIBUTO SELECCIONADO.
      let attrType = buttonSelected.getAttribute('data-info')
      //SE OBTIENE CLASE CLASS-DISABLE
      let disableClass = buttonSelected.getAttribute('class-disable')

      //SE QUITA LA CLASE QUE CONTIENE CLASS-LABELERROR (TITULO ATTR)
      let titleAttr = document.querySelector(`[data-bs='${attrType}']`)
      if(titleAttr != undefined) {  
        titleAttr.setAttribute('data-info', buttonSelected.value)
        titleAttr.classList.remove(titleAttr.getAttribute('class-labelError'))
      }

      //SE QUITA CLASE SELECTED A OTROS INPUT DEL MISMO TIPO QUE EL ATRIBUTO SELECCIONADO.
      attrInput.forEach(input => {
        if (input.getAttribute('data-info') == attrType) { //&& input !== e.target
          input.classList.remove(input.getAttribute('class-selected'))
          // input.classList.remove(input.getAttribute('class-disable'))
          input.removeAttribute('clicked')
        }
      })

      // //Si el atributo seleccionado tiene la clase class-disable
      // if (buttonSelected.classList.contains(disableClass)){
      //   console.log('contiene disable class')
      //   // buttonSelected.classList.remove(disableClass)
        
      //   //reset a los demas input y select
      //   attrSelect.forEach(select =>{
      //     // select.value = 'reset';
      //   })
      //   attrInput.forEach(input => {
      //     if (input !== buttonSelected) {
      //       // input.classList.remove(input.getAttribute('class-selected'))
      //       // input.classList.remove(input.getAttribute('class-disable'))
      //       // input.classList.remove(input.getAttribute('class-noneStock'))
            
      //       // input.removeAttribute('clicked')
      //       //Se elimina value seleccionado (en titulo) de otros input.
      //       titleAttr = document.querySelector(`[data-bs='${input.dataset.info}']`)
      //       if(titleAttr != undefined && input.dataset.info != buttonSelected.dataset.info) {
      //         // titleAttr.dataset.info = ""
      //       }
      //     }
      //   })
      // }

      let attrAvailable = attributesAvailable(buttonSelected)

      //SE AGREGA ATRIBUTO PARA SABER QUE ESTA SELECCIONADO.
      buttonSelected.setAttribute('clicked', '')
      //SE AGREGA CLASE QUE DA ESTILO AL INPUT SELECCIONADO.
      buttonSelected.classList.add(selectClass)

      let attrNoneStock = attributesNonStock(buttonSelected.value)
      //SE DEBEN DESABILITAR LOS OTROS ATRIBUTOS DE TIPO BUTTON NO DISPONIBLES EN BASE AL SELECCIONADO.
      attrInput.forEach(input => {
        if (input.dataset.info == attrType) return //No evalua del mismo  tipo
        if(attrAvailable.includes(input.value) ){ //&& input.getAttribute('data-info') != attrType
          // input.classList.remove(input.getAttribute('class-disable'))
          if(attrNoneStock.includes(input.value)){
            input.classList.add(input.getAttribute('class-disable'))
          }
          else {
            input.classList.remove(input.getAttribute('class-disable'))
          }
        }
        else {
          input.classList.add(input.getAttribute('class-disable'))
        }
      })

      //SE HACE RESET A LOS ATTR DE TIPO SELECT NO DISPONIBLES EN BASE AL SELECCIONADO.
      attrSelect.forEach(select => {
        if(!attrAvailable.includes(select.value)){ //&& input.getAttribute('data-info') != attrType
          select.value = 'reset';
        }
      })

      

      //SE COMPRUEBA SI ESTAN SELECCIONADO TODOS LOS ATRIBUTOS.
      let attributeSelected = attributesSelected()
      // let totalAttributes = bsale.products[0].attributes.map(fa => fa.name)
      let totalAttributes = bsale.products[0].attributes.filter(at => at.required == 1)
      if (totalAttributes.length === attributeSelected.length) {
        let vs = filterByAttr(attributeSelected)
        variantSelected(vs)
      }

      
      /////////////////////////
      // document.querySelector('#select-color').value = e.target.value
      // let event = document.createEvent("HTMLEvents");
      // event.initEvent("change",true,false);
      // document.querySelector('#select-color').dispatchEvent(event);
    }

    //------------------------
    //FUNCION LLAMADA CUANDO SE SELECCIONAN TODOS LOS ATRIBUTOS PREDETERMINADOS.
    function variantSelected(variant){
      //No hay variante disponible en base a los atributos seleccionados.
      if (variant.length === 0){
        defineButton(0);
      }
      //Hay más de 1 variante disponible en base a los atributos seleccionados.
      else if (variant.length > 1){
        console.log('mas de 1 variante con mismos atributos.')
        // defineButton(0);

        productVariant = []
        //Se debe buscar la variante que tiene stock.
        variant.forEach(v => {
            let stock = fullStock(v);
            if (stock == 0) { productVariant = v }
        })
        if (productVariant.length == 0) {
          productVariant = ''
        }
        updateVariant()
        

      }
      //Hay 1 variante disponible en base a los atributos seleccionados.
      else if(variant.length === 1){
        productVariant = variant[0]
        updateVariant()

        // elementos del dom
        for (const button of [...btnAdd, ...btnOrder]) {
          button.removeAttribute("disabled");
          button.style.cursor = "pointer";
        }
        for(const sku of skuHTML){
          sku.style.visibility = "visible";
        }
        
        for(const stock of stockHTML){
          stock.classList.remove('bs-error-stock')
        }
        
        for(const select of selector){
          select.style.visibility = "visible";  
        }
        
        
      }else{
        let attrVal = '';
        for(select of attrSelect){
          attrVal += `${select.value}  `
        }
        console.log('attrVal > ', attrVal);

        if(variant.length < 1){
          console.log("No se puede Filter por Atributos,No Hay variantes con los atributos seleccionados")
        }else if (variant.length > 1){
          console.log("No se puede Filter por Atributos,hay más de una variante con los atributos seleccionados")
        }
        
        console.log(variant)
        for(const button of [...btnAdd, ...btnOrder]) {
          button.setAttribute("disabled", true);
          button.style.opacity = ".5";
          button.style.cursor = "not-allowed";
          }

          for(const sku of skuHTML){
            sku.style.visibility = "hidden";
          }

          for(const stock of stockHTML){
            stock.innerHTML = `<strong>Opción "${attrVal}" No Disponible.</strong> </br>Por favor seleccione otros atributos del producto`;
            stock.classList.add('bs-error-stock')
          }

          for(const select of selector){
            select.style.visibility = "hidden";   
          }
      }
    }

    //----------------------------------------------------------
    //SE EJECUTA CUANDO ES SELECCIONADO UN ATRIBUTO DE UN SELECT.
    function newUpdateAttr(e){

      // if (e.target.value == 'reset') return

      let otherAttrSelect = Array.from(attrSelect).filter(sel => sel !== e.target)
      let otherAttrInput  = Array.from(attrInput)

      let optionSelected =  e.target.options[e.target.selectedIndex]
      let filters = []
      let newVariant; //variante seleccionanda
      
      //si la opcion seleccionada tiene clase bs-option-disabled
      if(optionSelected.classList.contains('bs-option-disabled')){
        
        // resetAttributes(otherAttrSelect, otherAttrInput)
        //hace reset en los otros selectores
        otherAttrSelect.forEach(select => {
          select.value = 'reset';
        })
        //hace reset a los otros input
        otherAttrInput.forEach(input => {
          // let classSelect  = input.getAttribute('class-selected')
          // let classDisable = input.getAttribute('class-disable')
        
          input.classList.remove(input.getAttribute('class-selected'))
          input.classList.remove(input.getAttribute('class-disable'))
          input.removeAttribute('clicked')
          // input.disabled = false
        })
        //texto de la option seleccionada es igual a su valor, elimina texto (no disponible)
        optionSelected.innerText = optionSelected.value
      }
      let attrAvailable = attributesAvailable(e.target)
      attrInput.forEach(input => {
        let classDisable = input.getAttribute('class-disable')
        if(!attrAvailable.includes(input.value)){
          input.classList.add(classDisable)
          // input.classList.remove(input.getAttribute('class-noneStock'))

        }
        else {
          // input.classList.remove(classDisable)
          let attrNoneStock = attributesNonStock(optionSelected.value)
          if(attrNoneStock.includes(input.value)){
            // console.log('add class none stock > ', input.getAttribute('class-noneStock'))
            // input.classList.add(input.getAttribute('class-noneStock'))
            // input.classList.remove(classDisable)

            //NEW
            input.classList.add(input.getAttribute('class-disable'))
          }
          else {
            input.classList.remove(classDisable)
            // input.classList.remove(input.getAttribute('class-noneStock'))
          }
        }
      })

      //define filtros
      //SE OBTIENEN LOS ATRIBUTOS SELECCIONADOS DE TIPO SELECT
      otherAttrSelect.forEach((select) => {
        if(select.value !== 'reset'){
          let attrSel = {
            name: select.dataset.info,
            value: select.value
          }
          filters.push(attrSel)
        }
      })

      //SE COMPRUEBA SI ESTAN SELECCIONADO TODOS LOS ATRIBUTOS.
      let attributeSelected = attributesSelected()
      let totalAttrInputSelected = []
      attrInput.forEach(ai => {
        if (ai.classList.contains(ai.getAttribute('class-selected')) && !ai.classList.contains(ai.getAttribute('class-disable'))) {
          console.log('ai > ', ai)
          totalAttrInputSelected.push(ai.dataset.info)
        }
      })
      let totalAttributesDom = attrSelect.length + totalAttrInputSelected.length
      // Si la cantidad de atributos seleccionados coincide con la cantiadad de selectores de atributos en el DOM.
      if (totalAttributesDom == attributeSelected.length) {
        let vs = filterByAttr(attributeSelected)
        console.log('vs > ', vs)
        variantSelected(vs)
      }

      //si los filtros agregados son igual a los
      // if(filters.length === attrSelect.length && false){
      //   let x = filterByAttr(filters)
      //   console.log('x > ', x)
      //   if(x.length === 1){
      //     productVariant = x[0]
      //     updateVariant()

      //     // elementos del dom
      //     for (const button of [...btnAdd, ...btnOrder]) {
      //       button.removeAttribute("disabled");
      //       button.style.cursor = "pointer";
      //     }
      //     for(const sku of skuHTML){
      //       sku.style.visibility = "visible";
      //     }
          
      //     for(const stock of stockHTML){
      //       stock.classList.remove('bs-error-stock')
      //     }
          
      //     for(const select of selector){
      //       select.style.visibility = "visible";  
      //     }
          
          
      //   }else{
      //     let attrVal = '';
      //     for(select of attrSelect){
      //       attrVal += `${select.value}  `
      //     }
      //     console.log(attrVal);

      //     if(x.length < 1){
      //       console.log("No se puede Filter por Atributos,No Hay variantes con los atributos seleccionados")
      //     }else if (x.length > 1){
      //       console.log("No se puede Filter por Atributos,hay más de una variante con los atributos seleccionados")
      //     }
          
      //     console.log(x)
      //     for(const button of [...btnAdd, ...btnOrder]) {
      //       button.setAttribute("disabled", true);
      //       button.style.opacity = ".5";
      //       button.style.cursor = "not-allowed";
      //       }
  
      //       for(const sku of skuHTML){
      //         sku.style.visibility = "hidden";
      //       }
  
      //       for(const stock of stockHTML){
      //         stock.innerHTML = `<strong>Opción "${attrVal}" No Disponible.</strong> </br>Por favor seleccione otros atributos del producto`;
      //         stock.classList.add('bs-error-stock')
      //       }
  
      //       for(const select of selector){
      //         select.style.visibility = "hidden";   
      //       }
      //   }
      // }//if(filters.length === attrSelect.length)
      
    }//function newUpdateAttr(e)

    //---------------------------------------------------------------------------
    //FUNCION PARA OBTENER LOS ATRIBUTOS SELECCIONADOS
    function attributesSelected() {
      let filtersSelected = []
      let hasFilter = false
      //SE OBTIENEN LOS ATRIBUTOS SELECCIONADOS DE TIPO SELECT
      attrSelect.forEach(select => { 
        if(select.value !== 'reset'){
          hasFilter = true
          let attrSel = {
            name: select.dataset.info,
            value: select.value
          }
          filtersSelected.push(attrSel)
        } 
      })

      //SE OBTIENEN LOS ATRIBUTOS SELECCIONADOS DE TIPO INPUT
      attrInput.forEach(input => {
        hasFilter = true
        if ( input.getAttribute('clicked')  != null){
          let attrSel = {
            name: input.dataset.info,
            value: input.value
          }
          filtersSelected.push(attrSel)
        }
      })
      
      if (!hasFilter) {
        filtersSelected.push({ name: 999 })
      }
      return filtersSelected
    }

    function defineButton(stock){
      // botones encargar o agregar al carro
      if (bsale.products[0].classification !== 1) {//si no es servicio
        if (stock > 0) {
          for (let i = 0; i < btnOrder.length; i++) {
            hideBtn(btnOrder[i])
          }
          for (let i = 0; i < btnAdd.length; i++) {
            showBtn(btnAdd[i])
          }
        } else {
          if (modalOrder !== null) { // no eixste el modal order, no se despliega el boton 
            for (let i = 0; i < btnOrder.length; i++) {
              showBtn(btnOrder[i])
            }
          }
          for (let i = 0; i < btnAdd.length; i++) {
            hideBtn(btnAdd[i])
          }
        }
      } else {
        // si es servicio siempre se puede agregar
        for (let i = 0; i < btnAdd.length; i++) {
          showBtn(btnAdd[i])
          btnAdd[i].removeAttribute("disabled")
        }
      }
    }

    function definePrice(variant){
      if (completePrice.length) {
        //precio del prudcto sin descuento
        let priceDefault = {
          progresive: "<span>{price} c/u</span><div>{finalPrice} c/u <small>Por compras desde {discountCant} unidades</small></div>",
          single: "<del>{price}</del><div>{finalPrice} c/u</div>",
          default: "<span>{price}</span>"
        }
        //info contiene el data-info del primer elemento '[data-bs="product.completePrice"]', si no tiene data-info carga htmlDefault
        let completePriceJSON = completePrice[0].dataset.info ? JSON.parse(completePrice[0].dataset.info) : JSON.parse(priceDefault);

        function newReplace(txt) {
          if (variant.discountRate > 0) {
            // si tiene descuento
            return txt
              .replace("{price}", oldPrice)
              .replace("{finalPrice}", formatPrice(variant.finalPrice))
              .replace("{discountCant}", variant.discountCant);
          } else {
            //si no tiene descuento
            return txt
              .replace("{finalPrice}", formatPrice(variant.finalPrice))
          }
        }
        // por cada elemento '[data-bs="product.completePrice"]'
        for (let i = 0; i < completePrice.length; i++) {
          let item = completePrice[i];
          
          // si no existe default crealo 
          /*
          if (!item.dataset.default) {
            const initial = item.innerHTML.replace(/\r?\n|\t\r/g, "");
            const initialPrice = document.querySelector('[data-bs="product.finalPrice"]') ? document.querySelector('[data-bs="product.finalPrice"]').innerHTML.replace(/\r?\n|\t\r/g, "") : undefined;
            item.dataset.default = initial.replace(initialPrice, "{finalPrice}");
          }
          */
          /*precio antes del descuento */
          if (variant.discountRate > 0) {
            
            //dibuja descuento
            for (let i = 0; i < discountTagHTML.length; i++) {
              let disc = discountTagHTML[i]
              let n = disc.innerText;
              disc.children[0].innerHTML = n.replace(/(\d*\.?\d+)+/, variant.discountRate);
              disc.style.opacity = "1";
            }

            if (variant.discountCant == 1) {
              item.innerHTML = newReplace(completePriceJSON.single);
            } else {
              item.innerHTML = newReplace(completePriceJSON.progresive);
            }
            // if (variant.discountCant > 1) {
            //   item.innerHTML = newReplace(completePriceJSON.progresive);
            // } else if (variant.discountCant == 1) {
            //   item.innerHTML = newReplace(completePriceJSON.single);
            // }
          } else { //si no tiene descuento
            //html inicial
            let d = finalPriceFormat || priceDefault.default;
            item.innerHTML = newReplace(d);
          }
        }//for 
      }
      
      //FORMA ANTIGUA
      if (variant.discountRate > 0) {
        //muestra
        for (let i = 0; i < discountTagHTML.length; i++) {
          let disc = discountTagHTML[i]
          let n = disc.innerText;
          disc.children[0].innerHTML = n.replace(/(\d*\.?\d+)+/, variant.discountRate);
          disc.style.opacity = "1";
        }

        for (let i = 0; i < discountInfoHTML.length; i++) {
          let e = discountInfoHTML[i];
          let old = e.querySelector("strike");
          if (old) {
            old.innerHTML = oldPrice;
          }else{
            e.innerHTML = discountInfoHTMLtxt[i].replace("{price}", `${oldPrice}`)
          }
          e.style.opacity = "1";
          e.style.display = "block";
        }

        if (variant.discountCant > 1) {
          
          for (let i = 0; i < discountConditionHTML.length; i++) {
            let e = discountConditionHTML[i];
            let old = e.querySelector("span");
            if (old) {
              old.innerHTML = variant.discountCant;
            }else{
              e.innerHTML = discountConditionHTMLtxt[i].replace("{discountCant}", variant.discountCant);
            }
            e.style.opacity = "1";
            e.style.display = "block";
          }

        } else {
          for (let i = 0; i < discountConditionHTML.length; i++) {
            let e = discountConditionHTML[i];
            e.style.display = "none";
            e.style.opacity = "0";
          }
        }
      } else {
        //oculta descuento
        for (let i = 0; i < discountTagHTML.length; i++) {
          discountTagHTML[i].style.opacity = "0";
        }
        for (let i = 0; i < discountConditionHTML.length; i++) {
          let e = discountConditionHTML[i];
          e.style.display = "none";
          e.style.opacity = "0";
        }
        //oculta variant info, precio anterior
        for (let i = 0; i < discountInfoHTML.length; i++) {
          let e = discountInfoHTML[i];
          e.style.opacity = "0";
          e.style.display = "none";
        }
      }

      //precio 
      for(let i = 0; i < productPrice.length; i++){
        productPrice[i].innerHTML = formatPrice(variant.finalPrice);
      }
      
    }

    function updateQuantity(e) {
      stock = fullStock(productVariant);
      // si existe un limite MAYOR a 0 corre ese, sino corre el stock
      let limit;
      if (Bsale.config.addToCartLimit > 0) {
        if (stock < 1) {
          limit = 1;
        } else if (stock < Bsale.config.addToCartLimit) {
          limit = stock;
        } else {
          limit = Bsale.config.addToCartLimit;
        }
      } else {
        if (stock < 1) {
          limit = 1
        } else {
          limit = stock;
        }
      }

      if (e) {
        // si el event es click y el elemeto clicado es quantity minus
        if (e.target.type = "click" && e.currentTarget.matches('[data-bs="product.quantity.minus"]')) {
          if (1 < quantity) {
            quantity--
          }
          // si el event es click y el elemeto clicado es quantity plus
        } else if (e.target.type = "click" && e.currentTarget.matches('[data-bs="product.quantity.plus"]')) {
          if (quantity < limit) {
            quantity++
          }
            
        } else if (e.target.type = "change" && e.currentTarget.matches('[data-bs="product.quantity"]')) {
          // si this.value no es un numero natural su valor es quantity (valor anterior)
          let inputValue = parseInt(this.value) || quantity;
          // if (bsale.products[0].hasDecimal == 1) inputValue = parseFloat(this.value).toFixed(1)

          //si input value es mayor a stock, es igual a stock;
          if (inputValue > limit) {
            inputValue = limit
            // si el valor es menor a 1 queda igual a uno
          } else if (inputValue < 1) {
            inputValue = 1;
          }
          quantity = inputValue;
        }
      } else {
        if (limit < quantity) {
          quantity = limit;
        } else if (quantity <= 1) {
          quantity = 1;
        }
      }
      for (let i = 0; i < quantityInput.length; i++) {
        quantityInput[i].value = quantity;
      }
      return quantity;
    }//updateQuantity

    //Descripciones del producto
    function toggleDescriptions() {
      var parent = $(this).data('parent');
      $(parent).find('button').removeClass('active');
      $(this).addClass("active");
    }

    function defineStock(newSelectVariant) {
      //var numbers
      let stockBreak = bsale.config.product.stock.break;
      let d = '{"0": "Agotado", "full": "Disponible", "unlimitedStock" : "Disponible"}'
      let dataConfig = stockHTML.length ? stockHTML[0].dataset.info ? stockHTML[0].dataset.info : d : d ;
      dataConfig = JSON.parse(dataConfig);
      stock = fullStock(newSelectVariant);
      // botones segun stock

      //mensaje stock 
      function StockMsg(obj, stock) {
        // se tiene que cambiar no funciona en internet explorer
        //var position = parseInt(Object.getOwnPropertyNames(obj).find(function (val) { return parseInt(val) >= stock }));
        let position = Object.keys(obj).filter(x => x >= stock)[0];
        
        if (obj.showUnits) {
          if (position <= 0) {
            return obj[position]
          } else {
            if (stock == Infinity) {
              return obj.full;
            } else {
              return `${obj.showUnits.before} ${stock} ${obj.showUnits.after}`;
            }
          }
        } else {
          // si stock es infinito (no controla stock)
          let m = "";
          if (stock === Infinity) {
            m = obj.unlimitedStock || "Disponible";
          }else if(stock === 0 ){
            m = obj[0] || "Agotado";
          } else {
            //si stock no esta definido retorna full 
            if (obj[position] == undefined) {
              m = obj["full"] || "Disponible";
            } else {
              //si no, devuelve la position
              m =  obj[position] || "Disponible";
            }
          }
          return m.replace("{cantidad}", stock)
        }//else
      }// end StockMsg

      function sucursalStock(stock) {
        for(const select of selector){
          if (select !== null) {
            const info = select.dataset.info ? JSON.parse(select.dataset.info) : undefined;
            let html = "";
            let header = "";
    
            if (info) {
              if (info.table) {
                header = info.tableTitle[0] ? `<thead><tr><th>${info.tableTitle[0]}</th><th>${info.tableTitle[1]}</th></tr></thead>` : "";
                for (let i = 0; i < stock.length; i++) {
                  let e = stock[i];
                  let st = Math.trunc(newSelectVariant.allowNegativeStock || newSelectVariant.unlimitedStock ? Infinity : e.quantityAvailable) 
                  html += `<tr><td>${e.office}</td><td>${StockMsg(info, st)}</td></tr>`;
                }
                select.innerHTML = `<table>${header}<tbody>${html}</tbody></table>`
              } else {
                for (let i = 0; i < stock.length; i++) {
                  let e = stock[i];
                  let st = Math.trunc(newSelectVariant.allowNegativeStock || newSelectVariant.unlimitedStock ? Infinity : e.quantityAvailable)
                  html += `<div>${e.office}</div><div>${StockMsg(info, st)}</div>`;
                }
                select.innerHTML = html
              }
            }
          }
        }
      }

      if (dataConfig && Object.keys(dataConfig).length !== 0) {
        for (let i = 0; i < stockHTML.length; i++) {
          stockHTML[i].innerHTML = StockMsg(dataConfig, stock);
        }

      } else {
        // old structure
        if (newSelectVariant.unlimitedStock || newSelectVariant.allowNegativeStock) {
          stockHTML.innerHTML = "";
        } else {
          if (stock > stockBreak) {
            stockHTML.innerHTML = bsale.config.product.stock.full;
          } else if (stock > 0 && stock <= stockBreak) {
            stockHTML.innerHTML = bsale.config.product.stock.min;
          }
          else {
            stockHTML.innerHTML = bsale.config.product.stock.out;
          }
        }
      }

      sucursalStock(newSelectVariant.stock);
    }// end: defineStock

    //agregar al carro
    function addToCart(e) {
      console.log('add to cart')
      const [product] = bsale.products

      //if (Array.from(attrSelect).filter(x => x.options.selectedIndex === 0).length <= 0) {
      let attrSelected = attributesSelected().map(as => as.name) 
      
      let attrAvailable = []
      bsale.products[0].attributes.forEach(aa => {
        if (aa.required) attrAvailable.push(aa.name)
      })

      attrSelected.sort()
      attrAvailable.sort()
      let result = attrAvailable.every((aa, i) => {
        return aa == attrSelected[i]
      })
      console.log(result)
      console.log(variantSelect.length )
      console.log(attrSelected[0] == 999)

      // Resut: valida si se han seleccionado los atributos obligatorios.
      // variantSelect.length: se ha seleccionado una variante. (checkbox) 
      if (result || variantSelect.length > 0 || attrSelected[0] == 999) {
        
        const thisBtn = this;
        const [variant] = product.variants.filter(variant => variant.id === productVariant.id)
        e.preventDefault()

        thisBtn.setAttribute("disabled", true);

        bsale.cart.add(variant, quantity)
          .then(cartItem => {
            updateCart(cartItem)
            thisBtn.removeAttribute("disabled");
            metrics.addToCart(cartItem)
          })
          .catch(function (error) {
            console.log(error)
            thisBtn.removeAttribute("disabled");
            newCreateModal("error", error.message);
          })
      }
      else {
        alertAttr()
      }
    }

    
    /*Al presionar el botón agregar al carro, se agrega la clase invalid al selector de atributo si no se han seleccionado todas las opciones, 
    cuando se selecionan se elimina la clase is-invalid.
    */
    function alertAttr() {
      //agrega invalid a selector de attr vacios
      for (let i = 0; i < attrSelect.length; i++) {
        let select = attrSelect[i];
        if (select.value === 'reset') {
          select.classList.add("is-invalid")
          // let titleNotSelected = select.parentElement.querySelector('.bs-product__label')
          let titleNotSelected = document.querySelector(`[data-bs='${select.value}']`)
          if (titleNotSelected != null){
            titleNotSelected.classList.add(titleNotSelected.getAttribute('class-labelError'))
          }
          // titleNotSelected.forEach(tns => tns.classList.add('is-invalid'))


          function clean() {
            select.classList.remove('is-invalid')
            titleNotSelected.classList.remove(titleNotSelected.getAttribute('class-labelError'))
            select.removeEventListener('focus', clean)
          }
          select.addEventListener('focus', clean)
        }
      }
      
      //agrega invalid a inputs de attr no seleccionados.
      let attributes = bsale.products[0].attributes
      attributes.forEach(attr => {
        let inputs = document.querySelectorAll(`button[data-info="${attr.name}"]`)
        if (inputs.length > 0){
          let inpSelected = Array.from(inputs).filter(inp => inp.getAttribute('clicked') === '')
          if (inpSelected.length == 0){
            // inputs.forEach(inp => inp.classList.add('is-invalid'))
            // let titleNotSelected = inputs[0].parentElement.querySelector('.bs-product__label')
            let titleNotSelected = document.querySelector(`[data-bs='${inputs[0].dataset.info}']`)
            titleNotSelected.classList.add(titleNotSelected.getAttribute('class-labelError'))
          }
        }
        
      })

      //levanta modal de error
      newCreateModal("error", "Debes seleccionar todos los atributos del producto");
    }

    function orderForm() {
      // si todos los atributos estan seleccionados levanta
      if(attrSelect.length > 0){
        if (Array.from(attrSelect).filter(x => x.options.selectedIndex === 0).length <= 0) {
          newCreateModal("order", productVariant)
        } else {
          newCreateModal("error", "Selecciona todos los atributos del producto")
        }
      }else{
        newCreateModal("order", productVariant)
      }
    }

    //ZOOM
    /*esta funcion permite realizar un zoom a la imagen del producto siempre cuando se visualize la vista en version desktop*/
    function initHoverZoom() {
      if (navigator.userAgent.toLowerCase().indexOf('mobile') < 0) {
        const slickItems = document.querySelectorAll("#bs-product-slider .item.slick-slide [data-bs='zoom']")
        slickItems.forEach(item => {
          const img = item.querySelector("img")
          new Drift(img, {
            inlinePane: false,
            containInline: true,
            paneContainer: item,
            zoomFactor: 2,
            sourceAttribute: 'src',
          })
        });
      }
    }

    $(document).on("click", "[data-bs='product.descriptions'] button", toggleDescriptions)

    ////////////////////
    // Eventos
    ///////////////////////

    updateVariant();

    //variant select
    for (let i = 0; i < variantSelect.length; i++) {
      let a = variantSelect[i];
      a.addEventListener("change", updateVariant);
      a.addEventListener("click", updateVariant);
    }

    //quantity
    for (let i = 0; i < quantityInput.length; i++) { quantityInput[i].addEventListener("change", updateQuantity, false); }
    for (let i = 0; i < quantityMinus.length; i++) { quantityMinus[i].addEventListener("click", updateQuantity, false); }
    for (let i = 0; i < quantityPlus.length; i++) { quantityPlus[i].addEventListener("click", updateQuantity, false); }

    //attr
    var event = new Event('build');
    for (let i = 0; i < attrSelect.length; i++) {
      attrSelect[i].addEventListener("change", newUpdateAttr, false);
      // attrSelect[i].addEventListener("build", newUpdateAttr, false);
      attrSelect[i].dispatchEvent(event);
      attrSelect[i].addEventListener("focus", clearAttr, false);
    }

    // 
    getVariantDuplicated()
    // console.log('variantDuplicated > ', variantDuplicated)
    let notAvailable = attributesNotAvailableByDuplicity()
    // console.log('notAvailable > ', notAvailable)
    //
    // let attributesRequired = bsale.products[0].attributes.filter(at => at.required == 1)
    let noneStock = attributesNonStock()
    // console.log('noneStock > ', noneStock)
    for (let i = 0; i < attrInput.length; i++) {
      attrInput[i].addEventListener("click", selectAttrInput, false);
      // if (attributesRequired.length == 1) {
        if (noneStock.includes(attrInput[i].value) || notAvailable.includes(attrInput[i].value)) {
          attrInput[i].classList.add(attrInput[i].getAttribute('class-disable'))
        }
      // }
    }

    //btn
    for (let i = 0; i < btnAdd.length; i++) {
      btnAdd[i].addEventListener("click", addToCart, false)
    }
    for (let i = 0; i < btnOrder.length; i++) {
      btnOrder[i].addEventListener("click", orderForm, false)
    }

  }
}